.booking{
   padding:2rem;
   border-radius: .5rem; 
   border:1px solid rgb(229,231,235);
   position: sticky;
   top:80px;
}
.booking__top h3{
    font-size: 2rem;
    font-weight: 700;
}
.booking__top h3 span{
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--text-color);
}
.booking__top span{
    font-size:1.1rem;
    font-weight: 600;
    color: var(--text-color);
}
.booking__top span i{
    color: var(--secondary-color);
}
.booking__top{
    padding-bottom:2rem;
    border-bottom: 1px solid rgb(229,231,235);
}
.booking__form{
    padding-top: 2rem;
}
.booking__form h5{
   margin-bottom: 1rem; 
}
.booking__form input{
    width: 100%;
    padding:0.5rem;
    border-radius: .5rem;
    color: var(--heading-color);
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid rgb(229,231,235);
    }
.booking__form input:focus{
    outline: none;
}
.booking__info-form{
   padding: 1rem; 
   border: 1px solid rgb(229, 231, 235);
}
.booking__bottom li{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.booking__bottom li h5, .booking__bottom li span{
    font-size: 1rem;
    color: var(--text-color);
    font-weight: 500;
}
.booking__bottom{
    margin-top: 1rem;
}
.total h5,.total span{
    color: var(--heading-color) !important;
    font-weight: 700 !important;
    font-size:1.1rem !important ;
}