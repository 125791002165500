.App {
    width: 100vw;
    height: 80vh;
    overflow: hidden;
  }
  
  .carousel, .carousel-item, .carousel-item video {
    width: 100%;
    height: 100%;
  }
  
  /* Modern and thicker controls for larger screens */
  .carousel-control-prev, .carousel-control-next {
    opacity: 1; /* Ensure visibility */
  }
  
  .carousel-control-prev-icon, .carousel-control-next-icon {
    visibility: visible;
    background-color: #000; /* Example color */
    border-radius: 50%;
    color: #fff; /* Icon color */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
    content: '';
    font-weight: bold;
  }
  

  .carousel-caption h1{
    font-weight: bolder;
    -webkit-text-stroke: 2px #fff;
  }
  .carousel-caption {
    right: 0;
    bottom: auto;
    top: 20px; /* Adjust as needed */
    left: auto;
    text-align: right;
    padding-right: 20px; 
    font-weight: bold;
    font-size: large;
    color: orange;
    /* Caption text color */
  }
  
  /* Responsive adjustments */
  @media (max-width: 991px) {
    .App {
        width: 100vw;
        height: 50vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 40px; /* Adjust for smaller screens */
      height: 40px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 20px; /* Adjust icon size */
    }
  
    .carousel-caption {
      top: 10px;
      padding-right: 15px;
      font-size: 14px; /* Smaller text */
    }
  }
  
  @media (max-width: 768px) {
    /* Further adjustments for even smaller devices like tablets and large phones */
    .App {
        width: 100vw;
        height: 40vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 35px;
      height: 35px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 16px;
    }
  
    .carousel-caption {
      top: 5px;
      padding-right: 10px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    /* Adjustments for small phones */
    .App {
        width: 100vw;
        height: 25vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 30px;
      height: 30px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 14px;
    }
  
    .carousel-caption {
      top: 2px;
      padding-right: 5px;
      font-size: 10px;
    }
  }
  @media (max-width: 920px) {
    /* Adjustments for small phones */
    .App {
        width: 100vw;
        height: 25vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 30px;
      height: 30px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 14px;
    }
  
    .carousel-caption {
      top: 2px;
      padding-right: 5px;
      font-size: 10px;
    }
  }
  @media (max-width: 1030px) {
    
    .App {
        width: 100vw;
        height: 40vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 30px;
      height: 30px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 14px;
    }
  
    .carousel-caption {
      top: 2px;
      padding-right: 5px;
      font-size: 10px;
    }
  }
  @media (max-width: 420px) {
    
    .App {
        width: 100vw;
        height: 25vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 30px;
      height: 30px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 14px;
    }
  
    .carousel-caption {
      top: 2px;
      padding-right: 5px;
      font-size: 10px;
    }
  }
  @media (max-width: 290px) {
    
    .App {
        width: 100vw;
        height: 22vh;
        
      }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 30px;
      height: 30px;
    }
  
    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
      font-size: 14px;
    }
  
    .carousel-caption {
      top: 2px;
      padding-right: 5px;
      font-size: 10px;
    }
  }