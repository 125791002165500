.tour__content img{
    width:100%;
    border-radius: .5rem;
    margin-bottom: 2.5rem;

}
.tour__info,.tour__reviews{
    
    border-radius: .5rem;
   border: 1px solid rgb(229,231,235);
   padding: 2rem;

}
.tour__info h2{
    font-size: 1.5rem;

}
.tour__info span{
    display: flex;
    align-items:center;
   column-gap:0.5rem;
    font-size: 1rem;
    color: var(--text-color);
}
.tour__info span i{
    color: var(--heading-color);
    font-size: 1.1rem;
}
.tour__extra-details{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    margin-top:1rem;
    margin-bottom: 2.5rem;
}
.tour__info h5{
    margin-bottom: 1rem;
    font-size: 1.3rem;

}
.tour__info p{
    line-height:30px;
    font-size: 1rem;
    color: var(--text-color);
}
.rating__group span{
font-size: 1.1rem;
display: flex;
align-items: center;
color: var(--secondary-color);
cursor: pointer;
}
.tour__reviews h4{
    margin-bottom: 3rem;
}
.review__input{
    display: flex;
    align-items: center;
    justify-content:space-between ;
    width: 100%;
    padding: 0.7rem;
    border-radius: 2rem;
    border: 1px solid var(--secondary-color);
}
.review__input input{
    width:100%;
    padding:0.5rem 0;
    border:none;
}
.review__input input:focus{
    outline: none;
}
.review__item{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;

}
.review__item img{
    width:3.5rem;
    height: 3.5rem;
    border-radius: 50px;
    object-fit:cover;

}
.user__reviews{
    margin-top: 2.5rem;
}
.review__item h5{
    font-size: 1rem;
    margin-bottom: 0;
}
.review__item p{
    font-size: .9rem;
    color:var(--text-color)
}
.review__item h6{
    font-size: 1.1rem;
    color: var(--text-color);
}
.review__item span i{
font-size: 1.2rem;
color: var(--secondary-color);
}
.review__item span{
    font-weight: 500;
    
}
@media only screen and (max-width:768px) {
    .tour__extra-details{
        flex-wrap: wrap;
        row-gap:1rem ;
    }
    .tour__extra-details span{
        font-size: 0.9rem;
    }
    .tour__extra-details span i{
font-size:1rem ;
    }
}
.button-wrapper {
    text-align: center; /* Center align the button */
  }
.email-button {
    background-color: var(--secondary-color); /* Ensure this variable is defined in your CSS */
    color: #1f2937; /* Dark grey text color for readability */
    padding: 8px 16px; /* Padding around the text */
    border-radius: 30px; /* Fully rounded corners for a pill shape */
    text-decoration: none; /* Remove underline from the link */
    font-size: 14px; /* Slightly smaller font size for inline display */
    font-weight: bold; /* Bold font weight */
    display: inline-block; /* Allows padding and width adjustment */
    margin-left: 10px; /* Space between the text and button */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .email-button:hover {
    background-color: #fbbf24; /* Slightly darker yellow on hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Larger shadow on hover */
    transform: translateY(-2px); /* Slight lift on hover for a dynamic effect */
  }