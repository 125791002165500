.about-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
  }
  
  .about-page h1, .about-page h3 {
    color: orange;
 /* A pleasant shade of blue, adjust as needed */
  }
  
  /* Styling for section content */
  .about-page p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Cancellation Policy and Terms Section */
  .policy-section, .terms-section {
    background-color: #f9f9f9; /* Light grey background for sections */
    border-radius: 5px;
    padding: 20px;
    margin-top: 30px;
  }
  
  .policy-section h3, .terms-section h3 {
    margin-bottom: 15px;
  }
  
  /* Responsive Grid adjustments */
  @media (max-width: 768px) {
    .about-page h1 {
      font-size: 24px; /* Smaller font size for smaller devices */
    }
  
    .about-page h3 {
      font-size: 18px;
    }
  }
  
  /* Enhancements for readability and visual appeal */
  .about-page h1 {
    margin-bottom: 30px;
  }
  
  .about-page h3 {
    color: #28a745; /* A vibrant shade of green, adjust as needed */
  }
  