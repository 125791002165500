.footer{
   padding-top: 70px; 
   padding-bottom: 30px;
}
.footer .logo img{
   width:50%;
    margin-bottom:1rem;
}
.footer .logo p{
    color: var(--text-color);
}
.social__links span a{
   text-decoration: none;
   color:var(--heading-color);
   font-size: 1.2rem; 
}
.footer__link-title{
    color:var(--heading-color) ;
    margin-bottom: 1rem;

}
.footer__quick-links li a{
   text-decoration: none;
   color: var(--text-color); 
   font-size: 1.1rem;
}
.footer__quick-links h6 span i{
    color: var(--secondary-color);
    font-size: 1.1rem;

}
.copyright{
    color: var(--text-color);
    font-size :1rem ;
}
.button-wrapper {
    text-align: center; /* Center align the button */
  }
  
  .email-button {
    background-color: var(--secondary-color); /* A modern shade of yellow */
    color: #1f2937; /* Dark grey text color for readability */
    padding: 10px 20px; /* Padding around the text */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline from the link */
    font-size: 16px; /* Medium font size */
    font-weight: bold; /* Bold font weight */
    display: inline-block; /* Allows padding and width adjustment */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for 3D effect */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .email-button:hover {
    background-color: #fbbf24; /* Slightly darker yellow on hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Larger shadow on hover */
  }